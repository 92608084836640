import CompB from "../components/CompB";
import CompC from "../components/CompC";
import BaseLayout from "../layout/BaseLayout";
import CompD from "../components/CompD";
import { Outlet } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import { FaBeer, FaDoorClosed, FaBell, FaCity, FaFish } from "react-icons/fa";
import { RiDashboardFill, RiSensorFill, RiDeviceFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { AiFillControl } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";

import { MdAssignment } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import { AiTwotoneNotification } from "react-icons/ai";
import { GiWaterTank } from "react-icons/gi";
import { RiPlantFill } from "react-icons/ri";
import { IoMdSwitch } from "react-icons/io";
import { ImLab } from "react-icons/im";

import TestComponent from "../components/TestComponent";
import AlertSettings from "../components/alert/alertSettings";
import CreateSensor from "../components/sensor/createSensor";
// import SensorMap from "../components/sensor/sensorMap";
import CreatePremises from "../components/premises/createPremises";
import ListPremises from "../components/premises/listPremises";
import DeviceConfiguration from "../components/device/deviceConfiguration";
import RoomConfiguration from "../components/premises/roomConfiguration";
// import Building from "../components/premises/building";
import { ImDatabase } from "react-icons/im";
import DataLogMain from "../components/dataLog/DataLogMain";
import RoomDetailsMain from "../components/dashboard/roomDetails/RoomDetailsMain";
import BasicTableComponent from "../components/BasicTableComponent";
import Login from "../components/auth/Login";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import Logout from "../components/auth/Logout";
import Premises from "../components/premises/premisesCreate/Premises";
import EnergyDashboard from "../components/energyMeter/EnergyDashboard";
import { ImPower } from "react-icons/im";
import EnergyDetailsMain from "../components/energyMeter/details/EnergyDetailsMain";
import Rooms from "../components/rooms/Rooms";
import Building from "../components/building/Building";
import SlaveAssign from "../components/slaveAssign/SlaveAssign";
import DeviceConfig from "../components/device-config/DeviceConfig";
import SensorMap from "../components/sensor-map/SensorMap";
import MotorControl from "../components/motor-control/MotorControl";
import NotificationMain from "../components/notification/NotificationMain";
import Tank from "../components/Tank/Tank";
import Fish from "../components/fish/Fish";
import FishTankAssign from "../components/fish/FishTankAssign";
import FishDesease from "../components/fish/FishDesease";
import FishFeed from "../components/fish/FishFeed";
import FishDailyFeed from "../components/fish/FishDailyFeed";
import FishLog from "../components/fish/FishLog";
import DailyFishLogMain from "../components/fish/fishLog/dailyFishLog/DailyFishLogMain";
import MonthlyFishLogMain from "../components/fish/fishLog/monthlyFishLog/MonthlyFishLogMain";
import GrossFishLogMain from "../components/fish/fishLog/grossFishLog/GrossFishLogMain";
import GeneratorDashboard from "../components/generator/dashboard/GeneratorDashboard";
import DashboardDetailsMain from "../components/generator/dashboardDetails/DashboardDetailsMain";
import GeneratorDataLogMain from "../components/generator/dataLog/GeneratorDataLogMain";
import InformationUpdate from "../components/generator/informationUpdate/InformationUpdate";
import DaywiseConsumption from "../components/generator/daywiseOilConsumptionReport/DaywiseConsumption";
import DataTableWithTotalCount from "../components/generator/daywiseOilConsumptionReport/DataTableWithTotalCount";
import OilConsumptionReport from "../components/generator/oilConsumptionReport/OilConsumptionReport";
import ChartReportsMain from "../components/generator/chartReports/ChartReportsMain";
import AnomalyReport from "../components/generator/anomalyReport/AnomalyReport";
import EnergyMeterDashboardUpdated from "../components/energyMeterUpdated/dashboard/EnergyMeterDashboardUpdated";
import EnergyMeterDashboardDetails from "../components/energyMeterUpdated/dashboard_details/EnergyMeterDashboardDetails";
import EnergyMeterDataLogMain from "../components/energyMeterUpdated/dataLog/EnergyMeterDataLogMain";
import EnergyConsumptionGraphMain from "../components/energyMeterUpdated/energy_consumption_graph/EnergyConsumptionGraphMain";
import MaintenanceReport from "../components/generator/maintenanceReport/MaintenanceReport";
import Billing from "../components/energyMeterUpdated/billing/Billing";
import BrriDashboard from "../components/brri/dashboard/BrriDashboard";
import SensorSwitch from "../components/sensor-switch/SensorSwitch";
import SoilDetailsGraph from "../components/brri/dashboard/soilDetails/SoilDetailsGraph";
import LabDashboard from "../components/brri/labDashboard/LabDashboard";
import LabDataLogMain from "../components/brri/labDataLog/LabDataLogMain";
import { encrypt, decrypt } from "../helper/urlEncrypt";
import LabDetailsLineChartMain from "../components/brri/labDashboard/detailsLineChart/LabDetailsLineChartMain";

//first route shouldnot have children might create issue on sidebar

export function CheckUserAccess() {
  const localAuth = localStorage.getItem("auth");
  const userName = JSON.parse(localAuth)?.user?.name;

  return userName === "zahid" || userName === "admin";
}
// const encryptedLabDashboardPath = encrypt("/lab-dashboard"); //library not working anymore
// const encryptedLabDataLogPath = encrypt("/lab-data-log");
export const routes = [
  {
    title: "Default",
    path: "/",
    icon: <RiDashboardFill />,
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    title: "Login",
    path: "/login",
    icon: <RiDashboardFill />,
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  // {
  //   title: "Dashboard",
  //   path: "/dashboard",
  //   icon: <RiDashboardFill />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       <Dashboard />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Energy Dashboard",
  //   path: "/energy_meter_dashboard",
  //   icon: <ImPower />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       {" "}
  //       <EnergyDashboard />
  //     </PrivateRoute>
  //   ),
  // },

  // {
  //   title: "Notification Log",
  //   path: "/notification",
  //   icon: <AiTwotoneNotification />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       {" "}
  //       <NotificationMain />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Room Details",
  //   path: "/dashboard/:roomId",
  //   icon: <ImDatabase />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <RoomDetailsMain />
  //     </PrivateRoute>
  //   ),
  // },

  // // {
  // //   title: "Energy Meter Details",
  // //   path: "/energy_meter_dashboard/:meterId",
  // //   icon: <ImDatabase />,
  // //   visibility: false,
  // //   element: (
  // //     <PrivateRoute>
  // //       <EnergyDetailsMain />
  // //     </PrivateRoute>
  // //   ),
  // // },

  // {
  //   title: "Sensor Management",
  //   path: "/sensor",
  //   icon: <RiSensorFill />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <CreateSensor />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Sensor Management",
  //   path: "/sensor",
  //   icon: <RiSensorFill />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "Create Sensor",
  //       path: "create-sensor",
  //       // icon: <FaBeer />,
  //       element: (
  //         <PrivateRoute>
  //           <CreateSensor />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },

  // {
  //   title: "Premises  ",
  //   path: "/premises",
  //   icon: <FaCity />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <Premises />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Premises  ",
  //   path: "/premises",
  //   icon: <FaCity />,
  //   visibility: true,

  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "Create Premises ",
  //       path: "create-premises",
  //       icon: <FaBeer />,
  //       element: (
  //         <PrivateRoute>
  //           <Premises />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Create Building",
  //       path: "building-create",
  //       icon: <FaBeer />,
  //       element: (
  //         <PrivateRoute>
  //           <Building />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Create Room ",
  //       path: "room-create",
  //       icon: <FaBeer />,
  //       element: (
  //         <PrivateRoute>
  //           <Rooms />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "Slave Assign",
  //   path: "/slave-assign",
  //   icon: <MdAssignment />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <SlaveAssign />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Slave Assign",
  //   path: "/slave-assign",
  //   icon: <MdAssignment />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "Device Assign",
  //       path: "device-assign",
  //       element: (
  //         <PrivateRoute>
  //           <SlaveAssign />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Sensor Map",
  //       path: "sensor-map",
  //       element: (
  //         <PrivateRoute>
  //           <SensorMap />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },

  // {
  //   title: "Tank Create",
  //   path: "/tank-create",
  //   icon: <GiWaterTank />,
  //   visibility: true,
  //   element: <Tank />,
  // },
  // {
  //   title: "Fish",
  //   path: "/fish",
  //   icon: <FaFish />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<Fish />},
  //     {
  //       title: "Fish Create",
  //       path: "fish-create",
  //       element: (
  //         <PrivateRoute>
  //           <Fish />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Assign",
  //       path: "fish-assign",
  //       element: (
  //         <PrivateRoute>
  //           <FishTankAssign />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Desease",
  //       path: "fish-desease",
  //       element: (
  //         <PrivateRoute>
  //           <FishDesease />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Feed",
  //       path: "fish-feed",
  //       element: (
  //         <PrivateRoute>
  //           <FishFeed />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Daily Feed",
  //       path: "fish-daily-feed",
  //       element: (
  //         <PrivateRoute>
  //           <FishDailyFeed />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Fish Log",
  //       path: "fish-log",
  //       element: (
  //         <PrivateRoute>
  //           <FishLog />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "Fish Report",
  //   path: "/fish-report",
  //   icon: <MdAssignment />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<CompB/>},

  //     // {
  //     //   title: "Daily Fish with Child",
  //     //   path: "daily-fish-report-with-child",

  //     //   children: [

  //     //     {
  //     //       title: "Monthly Fish Report",
  //     //       path: "monthly-fish-report",
  //     //       element: (
  //     //         <PrivateRoute>
  //     //           <MonthlyFishLogMain />
  //     //         </PrivateRoute>
  //     //       ),
  //     //       // loader: teamLoader,
  //     //     },
  //     //   ],
  //     // },
  //     // {
  //     //   title: "Daily Fish with Child",
  //     //   path: "daily-fish-report-with-child",

  //     //   children: [

  //     //     {
  //     //       title: "Monthly Fish Copy",
  //     //       path: "monthly-fish-report",
  //     //       element: (
  //     //         <PrivateRoute>
  //     //           <MonthlyFishLogMain />
  //     //         </PrivateRoute>
  //     //       ),
  //     //       // loader: teamLoader,
  //     //     },
  //     //     {
  //     //       title: "Monthly Fish Copy",
  //     //       path: "monthly-fish-report",
  //     //       element: (
  //     //         <PrivateRoute>
  //     //           <MonthlyFishLogMain />
  //     //         </PrivateRoute>
  //     //       ),
  //     //       // loader: teamLoader,
  //     //     },
  //     //   ],
  //     // },

  //     {
  //       title: "Daily Fish Report",
  //       path: "daily-fish-report",
  //       element: (
  //         <PrivateRoute>
  //           <DailyFishLogMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Monthly Fish Report",
  //       path: "monthly-fish-report",
  //       element: (
  //         <PrivateRoute>
  //           <MonthlyFishLogMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Gross Fish Report",
  //       path: "gross-fish-report",
  //       element: (
  //         <PrivateRoute>
  //           <GrossFishLogMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "Generator",
  //   path: "/generator",
  //   icon: <ImDatabase />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <GeneratorDashboard />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   title: "Generator",
  //   path: "/generator",
  //   icon: <AiFillSetting />,
  //   visibility: true,
  //   children: [
  //     {
  //       title: "Dashboard",
  //       path: "dashboard",
  //       element: (
  //         <PrivateRoute>
  //           <GeneratorDashboard />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Data Log",
  //       path: "data-log",
  //       element: (
  //         <PrivateRoute>
  //           <GeneratorDataLogMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },

  //     // {
  //     //   title: "Running Hour Report ",
  //     //   path: "runningHour-report",
  //     //   element: (
  //     //     <PrivateRoute>
  //     //       <DaywiseConsumption />
  //     //     </PrivateRoute>
  //     //   ),
  //     //   // loader: teamLoader,
  //     // },
  //     {
  //       title: "Operating Report",
  //       path: "operating-report",
  //       element: (
  //         <PrivateRoute>
  //           <OilConsumptionReport />
  //           {/* <DataTableWithTotalCount /> */}
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Graphical Dashboard",
  //       path: "graphical-dashboard",
  //       element: (
  //         <PrivateRoute>
  //           <ChartReportsMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Anomaly Report",
  //       path: "anomaly-report",
  //       element: (
  //         <PrivateRoute>
  //           <AnomalyReport />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Update Info",
  //       path: "update-info",
  //       element: (
  //         <PrivateRoute>
  //           <InformationUpdate />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Maintenance Report ",
  //       path: "maintenance-report",
  //       element: (
  //         <PrivateRoute>
  //           <MaintenanceReport />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "Generator Dashboard Details",
  //   path: "/generator/dashboard/:combined_id",
  //   icon: <ImDatabase />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <DashboardDetailsMain />
  //     </PrivateRoute>
  //   ),
  // },

  // {
  //   title: "Energy Meter",
  //   path: "/energy-meter",
  //   icon: <ImDatabase />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <EnergyMeterDashboardUpdated />
  //     </PrivateRoute>
  //   ),
  // },

  // {
  //   title: "Energy Meter",
  //   path: "/energy-meter",
  //   icon: <ImPower />,
  //   visibility: true,
  //   children: [
  //     {
  //       title: "Dashboard",
  //       path: "dashboard",
  //       element: (
  //         <PrivateRoute>
  //           <EnergyMeterDashboardUpdated />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Data Log",
  //       path: "data-log",
  //       element: (
  //         <PrivateRoute>
  //           <EnergyMeterDataLogMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Energy Consuption",
  //       path: "energy-consumption",
  //       element: (
  //         <PrivateRoute>
  //           <EnergyConsumptionGraphMain />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Billing",
  //       path: "billing",
  //       element: (
  //         <PrivateRoute>
  //           <Billing />
  //         </PrivateRoute>
  //       ),
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "Energy Meter Details",
  //   path: "/energy-meter/:meterId",
  //   icon: <ImDatabase />,
  //   visibility: false,
  //   element: (
  //     <PrivateRoute>
  //       <EnergyMeterDashboardDetails />
  //     </PrivateRoute>
  //   ),
  // },

  // {
  //   title: "Device Configuration",
  //   path: "/device-config",
  //   icon: <RiDeviceFill />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       <DeviceConfig />
  //     </PrivateRoute>
  //   ),
  // },

  {
    title: "Brri",
    path: "/brri",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <BrriDashboard />
      </PrivateRoute>
    ),
  },
  {
    title: "Soil Details Graph",
    path: "/soilDetails/:slaveId",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <SoilDetailsGraph />
      </PrivateRoute>
    ),
  },
  {
    title: "Brri",
    path: "/brri",
    icon: <RiPlantFill />,
    visibility: true,
    children: [
      {
        title: "Dashboard",
        path: "dashboard",
        element: (
          <PrivateRoute>
            <BrriDashboard />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
    ],
  },
  {
    title: "Data Log",
    path: "/data_log",
    icon: <ImDatabase />,
    visibility: true,
    element: (
      <PrivateRoute>
        {" "}
        <DataLogMain />
      </PrivateRoute>
    ),
  },

  {
    title: "Lab-Main",
    // path: encryptedLabDashboardPath,
    path: "/lab-dashboard",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <LabDashboard />
      </PrivateRoute>
    ),
  },
  {
    title: "Lab-Details",
    path: "/lab-line-chart",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <LabDetailsLineChartMain />
      </PrivateRoute>
    ),
  },
  {
    title: "Lab",
    // path: encryptedLabDashboardPath, //library not working
    path: "/lab-dashboard",
    icon: <ImLab />,
    visibility: true,
    children: [
      {
        title: "Dashboard",
        path: "dashboard",
        element: (
          <PrivateRoute>
            <LabDashboard />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Lab Data Log",
        // path: encryptedLabDataLogPath, //library not working
        path: "lab-data-log",
        element: (
          <PrivateRoute>
            <LabDataLogMain />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
    ],
  },
  {
    title: "Motor Control",
    path: "/motor-control",
    icon: <AiFillControl />,
    visibility: true,
    element: (
      <PrivateRoute>
        <MotorControl />
      </PrivateRoute>
    ),
  },
  {
    title: "Sensor Switch",
    path: "/sensor-switch",
    icon: <IoMdSwitch />,
    visibility: true,
    element: (
      <PrivateRoute>
        <SensorSwitch />
      </PrivateRoute>
    ),
  },
  {
    title: "Alert Settings",
    path: "/alert",
    icon: <FaBell />,
    visibility: true,
    element: (
      <PrivateRoute>
        <AlertSettings />
      </PrivateRoute>
    ),
  },
  {
    title: "logout",
    path: "/logout",
    icon: <FiLogOut />,
    visibility: true,
    element: <Logout />,
  },
  {
    path: "*",
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  }, //to redirect unknown routes during login
];
