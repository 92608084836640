import React from "react";
import "./style/dataLog.css";
import { Table, Pagination } from "rsuite";
import { useEffect } from "react";
import { useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";

const { Column, HeaderCell, Cell } = Table;
// const defaultData = mockUsers(100);

const AllDataLog = ({
  tableData,
  setActivePage,
  setDataView,
  activePage,
  totalDataCount,
}) => {
  const theme = useContext(ThemeContext);
  const [limit, setLimit] = React.useState(10);

  // const [page, setPage] = React.useState(4);
  const [totalData, setTotalData] = useState(totalDataCount);

  const handleChangeLimit = (dataKey) => {
    // setPage(1);
    setLimit(dataKey);
    setActivePage(1);
    // console.log(dataKey);

    setDataView(dataKey);
  };
  const setActivePageHandler = (dataKey) => {
    // setLoading(true);
    setActivePage(dataKey);
  };
  useEffect(() => {
    setTotalData(totalDataCount);
  }, [totalDataCount]);

  // const handlePageChange = datakey =>{
  //   alert ("page change");

  // }

  // const data = defaultData.filter((v, i) => {
  //   const start = limit * (page - 1);
  //   const end = start + limit;

  //   return i >= start && i < end;
  // });
  let data = [...tableData];
  const loadingHandler = () => {
    // return true;
    // return setTimeout(myGreeting, 100);
    // function myGreeting(){
    //   return true;
    // }
    return false;
  };

  return (
    <div className="theme-background theme-text">
      <Table
        // loading={data.length>0?false:loadingHandler}
        loading={tableData.length > 0 ? false : true}
        height={500}
        data={data}
        renderEmpty={() => {
          return <div className="rs-table-body-info">No Data Available</div>;
        }}

        // className={`${theme} `}
        // style={{ background: "red" }}
      >
        <Column
          width={80}
          align="center"
          fixed
          className={`theme-text theme-table-background`}
        >
          <HeaderCell style={{ color: "#6C88AF" }}>Id</HeaderCell>
          <Cell dataKey="id" />
        </Column>

        <Column width={200} className={`theme-text theme-table-background`}>
          <HeaderCell style={{ color: "#6C88AF" }}>Device Code</HeaderCell>
          <Cell dataKey="device_code" />
        </Column>

        <Column width={200} className={`theme-text theme-table-background`}>
          <HeaderCell style={{ color: "#6C88AF" }}>Area Name</HeaderCell>
          <Cell dataKey="room_name" />
        </Column>

        <Column width={100} className={`theme-text theme-table-background`}>
          <HeaderCell style={{ color: "#6C88AF" }}>Level</HeaderCell>
          <Cell dataKey="level" />
        </Column>
        <Column width={200} className={`theme-text theme-table-background`}>
          <HeaderCell style={{ color: "#6C88AF" }}>Slave</HeaderCell>
          <Cell dataKey="slave_address" />
        </Column>
        <Column width={150} className={`theme-text theme-table-background`}>
          <HeaderCell style={{ color: "#6C88AF" }}>Sensor Name</HeaderCell>
          <Cell dataKey="sensor_name" />
        </Column>
        <Column width={150} className={`theme-text theme-table-background`}>
          <HeaderCell style={{ color: "#6C88AF" }}>Sensometer Id</HeaderCell>
          <Cell dataKey="sensometer_id" />
        </Column>
        <Column width={100} className={`theme-text theme-table-background`}>
          <HeaderCell style={{ color: "#6C88AF" }}>Value</HeaderCell>
          <Cell dataKey="value" />
        </Column>
        <Column
          width={200}
          flexGrow={1}
          className={`theme-text theme-table-background`}
          fullText
        >
          <HeaderCell style={{ color: "#6C88AF" }}>Time</HeaderCell>
          <Cell dataKey="created_at" />
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="lg"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalData}
          // total={10}
          limitOptions={[10, 30, 50, 100, 200]}
          limit={limit}
          // activePage={page}
          // onChangePage={setPage}
          activePage={activePage}
          onChangePage={setActivePageHandler}
          onChangeLimit={handleChangeLimit}
          // onChangePage={handlePageChange}
          className={`theme-text`}
        />
      </div>
      {/* <Pagination
        prev
        last
        next
        first
        size="lg"
        total={totalDataCount}
        limit={limit}
        maxButtons={5}
        activePage={activePage}
        onChangePage={setActivePage}
        boundaryLinks
      /> */}
    </div>
  );
};
export default AllDataLog;
