import { apiSlice } from "../api/apiSlice";

export const brriLabDataLogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    brriLabDataLogFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/lab-datatables",
        method: "POST",
        body: data,
      }),
    }),
    brriLabSensorList: builder.query({
        query: () => "/apiV2/brri-lab-sensor-filter",
      }),
  }),
});

export const { useBrriLabDataLogFetchMutation,useBrriLabSensorListQuery } = brriLabDataLogApi;
