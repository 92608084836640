import { apiSlice } from "../api/apiSlice";

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrriDashboardData: builder.query({
      query: () => "/apiV2/dashboard",
    }),
    // updateDeviceConfigList: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/devices-config",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["deviceConfig"],
    // }),
  }),
});

export const {
  useGetBrriDashboardDataQuery,
  useLazyGetBrriDashboardDataQuery,
  //   useUpdateDeviceConfigListMutation,
} = dashboardApi;
