import React from "react";
import BrriLineChart from "./BrriLineChart";

function BrriGraphCard({ title, icon, unit, value, chartData }) {
  return (
    <div>
      <div className="card theme-background brri-graph-container">
        <div className="row">
          <div className="col-md-3 brri-graph-container">
            <div className="brri-graph-title theme-text">
              {title || "title"}
            </div>
            <div className="brri-graph-icon">
              <img src={icon || ""}></img>
            </div>
            {/* <div className="brri-graph-value theme-text">{value || 0}</div> */}
            <div
              className="brri-graph-value theme-text"
              style={{ textAlign: "left", fontSize: "20px" }}
            >
              {value || 0}
              <span className="brri-unit">{unit}</span>
            </div>
          </div>
          <div className="col-md-9">
            <BrriLineChart title={title} chartData={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrriGraphCard;
