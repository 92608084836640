import React, { useContext, useEffect } from "react";
import useTitle from "../../../hooks/useTitle";
import { ThemeContext } from "../../../context/ThemeContext";
import atm_pressure from "./asset/atm_pressure.png";
import wind_icon from "./asset/wind.png";
import solar_voltage from "./asset/solar_voltage.png";
import battery_voltage from "./asset/battery_voltage.png";
import battery_charging_current from "./asset/battery_charging_current.png";
import batter_charging from "./asset/battery_charging.png";

//graph icons
import co2 from "./asset/graphAssets/co2.png";
import rainfall from "./asset/graphAssets/rainfall.png";
import co from "./asset/graphAssets/co.png";
import ch4 from "./asset/graphAssets/ch4.png";
import pm2_5 from "./asset/graphAssets/pm2_5.png";
import pm_10 from "./asset/graphAssets/pm_10.png";
import temp from "./asset/graphAssets/temp.png";
import humidity from "./asset/graphAssets/humidity.png";

//soil icons
import ph from "./asset/soilAssets/ph.png";
import moisture from "./asset/soilAssets/moisture.png";
import conductivity from "./asset/soilAssets/conductivity.png";
import nitrogen from "./asset/soilAssets/nitrogen.png";
import phosphorus from "./asset/soilAssets/phosphorus.png";
import potassium from "./asset/soilAssets/potassium.png";
import water_level from "./asset/soilAssets/water_level.png";

import "./styles/brriStyle.css";
import BrriValueCard from "./BrriValueCard";
import BrriGraphCard from "./BrriGraphCard";
import BrriLineChart from "./BrriLineChart";
import BrriGauge from "./BrriGauge";
import Temp from "./Temp";
import { useGetBrriDashboardDataQuery } from "../../../features/brri/dashboardApi";
import { useLazyGetBrriDashboardDataQuery } from "../../../features/brri/dashboardApi";

import { useGetBrriLabDashboardDataQuery } from "../../../features/brri/labDashboardApi";
import { useLazyGetBrriLabDashboardDataQuery } from "../../../features/brri/labDashboardApi";

import mqtt from "mqtt/dist/mqtt";
import timeAgo from "../../../helper/timeAgo";
import BrriSoilCard from "./BrriSoilCard";

import { generatePath, useNavigate } from "react-router-dom";

import { TbListDetails } from "react-icons/tb";

function LabDashboard() {
  useTitle("Lab Dashboard");
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  // let { data, isLoading } = useGetBrriDashboardDataQuery();
  // const [getBrriDashboardData, results] = useLazyGetBrriDashboardDataQuery();

  let { data, isLoading } = useGetBrriLabDashboardDataQuery();
  const [getBrriLabDashboardData, results] =
    useLazyGetBrriLabDashboardDataQuery();

  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8082,
  };

  let client = mqtt.connect("mqtts://mqtt.aqualinkbd.com", options);
  useEffect(() => {
    client.subscribe("/dashboard/brri_lab");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      getBrriLabDashboardData();
    });
  }, [client]);

  const icons = {
    SOLARVOLTAGE: solar_voltage,
    BATTERYVOLTAGE: battery_voltage,
    BATTERYCHARGINGCURRENT: battery_charging_current,
    DEVICECURRENT: batter_charging,
    ATMPRESSURE: atm_pressure,
    WINDSPEED: wind_icon,
    WINDDIRECTION: wind_icon,
    "PM2.5": pm2_5,
  };
  // const graphIcons = {
  //   TEMP: temp,
  //   HUM: humidity,
  //   RAINFALL: rainfall,
  //   CO2: co2,
  //   CO: co,
  //   CH4: ch4,
  //   PM10: pm_10,
  //   ATMPRESSURE: atm_pressure,
  //   "PM2.5": pm2_5,
  // };
  // const soilIcons = {
  //   PH: ph,
  //   MOISTURE: moisture,
  //   SOILTEMP: temp,
  //   CONDUCTIVITY: conductivity,
  //   NITROGEN: nitrogen,
  //   PHOSPHORUS: phosphorus,
  //   POTASSIUM: potassium,
  //   WATERLEVEL: water_level,
  // };
  const graphIcons = {
    PH: ph,
    MOISTURE1: moisture,
    MOISTURE2: moisture,
    SOILTEMP1: temp,
    SOILTEMP2: temp,
    CONDUCTIVITY1: conductivity,
    CONDUCTIVITY2: conductivity,
    NITROGEN: nitrogen,
    PHOSPHORUS: phosphorus,
    POTASSIUM: potassium,
    WATERLEVEL: water_level,
  };
  let brriCardData = [];
  let gaugeData = [];
  let soil_device_1_data = [];
  let soil_device_2_data = [];
  let soil_device_3_data = [];
  let soil_device_4_data = [];

  let time_ago = "";
  if (!isLoading) {
    if (results.data) {
      // console.log("results");
      // console.log(results.data);
      data = results?.data;
    }
    // console.log(data);
    // brriCardData = data?.data;
    gaugeData = data?.soil_device_5;
    // soil_device_1_data = data?.soil_device_1;
    // soil_device_2_data = data?.soil_device_2;
    // soil_device_3_data = data?.soil_device_3;
    // soil_device_4_data = data?.soil_device_4;

    // brriCardData = brriCardData.map((item) => {
    //   const iconName = item.sensor_name.split(" ").join("");

    //   return {
    //     ...item,
    //     iconName: iconName,
    //   };
    // });
    gaugeData = gaugeData.map((item) => {
      const iconName = item.sensor_name.split(" ").join("");

      return {
        ...item,
        iconName: iconName,
      };
    });
    // soil_device_1_data = soil_device_1_data.map((item) => {
    //   const iconName = item.sensor_name.split(" ").join("");

    //   return {
    //     ...item,
    //     iconName: iconName,
    //   };
    // });
    // soil_device_2_data = soil_device_2_data.map((item) => {
    //   const iconName = item.sensor_name.split(" ").join("");

    //   return {
    //     ...item,
    //     iconName: iconName,
    //   };
    // });
    // soil_device_3_data = soil_device_3_data.map((item) => {
    //   const iconName = item.sensor_name.split(" ").join("");

    //   return {
    //     ...item,
    //     iconName: iconName,
    //   };
    // });
    // soil_device_4_data = soil_device_4_data.map((item) => {
    //   const iconName = item.sensor_name.split(" ").join("");

    //   return {
    //     ...item,
    //     iconName: iconName,
    //   };
    // });
    let latestTimeStamp = new Date(gaugeData[0]?.updated_at);
    // console.log(latestTimeStamp);

    if (latestTimeStamp) {
      time_ago = timeAgo(latestTimeStamp);
    }
  }

  const detailsClickHandler = () => {
    navigate("/lab-line-chart");
  };

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        {/* <div
          className="card theme-background"
         
        >
          <div
            className="brri-timestamp theme-text"
            style={{ textAlign: "center", padding: "10px", fontSize: "18px" }}
          >
            Last Updated : {time_ago} (BRRI Gazipur)
          </div>
          <div
            className="details-text"
            // onClick={() => roomClickHandler(roomId)}
          >
            <TbListDetails />
            details
          </div>
        </div> */}
        <div className="card theme-background" style={{ padding: "10px" }}>
          <div
            className="brri-timestamp theme-text"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "18px",
            }}
          >
            <div>Last Updated : {time_ago} (BRRI Gazipur)</div>
            <div
              className="details-text"
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => detailsClickHandler()}
            >
              <TbListDetails style={{ marginRight: "5px" }} />
              details
            </div>
          </div>
        </div>

        <div className="row">
          {gaugeData?.map((gaugeItem) => {
            const chartData = {
              label: gaugeItem?.chart_Label,
              value: gaugeItem?.chart_value,
            };
            return (
              <div className="col-md-6 col-xl-4 col-lg-4 col-sm-6">
                <BrriGraphCard
                  value={gaugeItem?.value}
                  title={gaugeItem?.sensor_name}
                  unit={gaugeItem?.unit}
                  icon={graphIcons[gaugeItem?.iconName]}
                  chartData={chartData}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LabDashboard;
