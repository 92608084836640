import React, { useState, useEffect } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import "./styles/motorControl.css";
import { useMotorOnOffMutation } from "../../features/motor-contorl/motorControlApi";
import { useGetMotorStatusQuery } from "../../features/motor-contorl/motorControlApi";
import Swal from "sweetalert2";
import useTitle from "../../hooks/useTitle";

import { useSensorSwitchMutation } from "../../features/sensor-switch/sensorSwitchApi";
import { useGetSensorSwitchStatusQuery } from "../../features/sensor-switch/sensorSwitchApi";

function SensorSwitch() {
  useTitle("Sensor Switch");
  const theme = useContext(ThemeContext);
  const [onOffServerStatus, setOnOffServerStatus] = useState(false);

  const [sensorSwitch] = useSensorSwitchMutation();

  const { data: sensorStatus, isLoading } = useGetSensorSwitchStatusQuery();

  useEffect(() => {
    if (!isLoading) {
      const sensorStatusData = sensorStatus?.data;

      if (sensorStatusData) {
        if (sensorStatusData.io_command == "0") {
          setOnOffServerStatus(false);
        } else {
          setOnOffServerStatus(true);
        }
      }
    }
  }, [isLoading, sensorStatus]);

  const switchChangeHandler = (e) => {
    let motorStatus = e.target.checked ? "1" : "0"; //0=false or 1=true
    setOnOffServerStatus(!onOffServerStatus);
    sensorSwitch({
      io_command: motorStatus,
    })
      .unwrap()
      .then((payload) => {
        if (motorStatus == "1") {
          Swal.fire(payload.message, "Sensor Status Switched to ON", "success");
        } else {
          Swal.fire(
            payload.message,
            "Sensor Status Switched to OFF",
            "success"
          );
        }
      })
      .catch((error) =>
        Swal.fire({
          icon: "error",
          title: error.status,
          text: "Something went wrong!",
        })
      );
  };

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="row">
          <div class="col-md-6">
            <div className="room-card card theme-background">
              <h4 className="room-name">Sensor Switch</h4>

              <div className="motor-on-off-switch">
                <h5 className={`off-label theme-text`}>Off</h5>
                <div className="only-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={switchChangeHandler}
                      checked={onOffServerStatus}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <h5 className={`theme-text`}>On</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SensorSwitch;
