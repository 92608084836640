import React, { useEffect, useState } from "react";
import { useLabLineChartDataFetchMutation } from "../../../../features/brri/labDetailsLineChartApi";
import LabLineChart from "./LabLineChart";

function LabLineChartContainer() {
  const [formData, setFormData] = useState({});

  const [labLineChartDataFetch, { data, isLoading }] =
    useLabLineChartDataFetchMutation();
  useEffect(() => {
    labLineChartDataFetch();
  }, []);
  let element = "";
  if (isLoading) {
    element = <div>Loading</div>;
  }

  if (!isLoading) {
    // console.log(JSON.stringify(data));
    if (data) {
      const entries = Object.entries(data);

      element = entries?.map(([key, chartData]) => {
        // console.log(value);
        return (
          <LabLineChart
            key={key}
            chartData={chartData}
            // valueArray={valueArray}
            // dateArray={dateArray}
            // sensorType={key}
          />
        );
      });
    }
  }

  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    labLineChartDataFetch(formData);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-10">
          <form onSubmit={(e) => formSubmitHandler(e)}>
            <div className="row">
              <div className="form-group col-md-2">
                <label className="theme-text">Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  //   onChange={(e) =>
                  //     setDate({ ...date, startdate: e.target.value })
                  //   }
                  name="startdate"
                  onChange={(e) => {
                    dataChangeHandler(e);
                  }}
                  required
                />
              </div>
              <div class="form-group col-md-2">
                <label className="theme-text">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  //   onChange={(e) =>
                  //     setDate({ ...date, enddate: e.target.value })
                  //   }
                  name="enddate"
                  onChange={(e) => {
                    dataChangeHandler(e);
                  }}
                  required
                />
              </div>

              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {element}
    </div>
  );
}

export default LabLineChartContainer;
