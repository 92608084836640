import React from "react";
import "./styles/brriStyle.css";

function BrriValueCard({ icon, value, unit, title }) {
  return (
    <div>
      <div className="zero-padding">
        <div className="card  theme-background card-container">
          <div className="value-icon-container">
            <div className="brri-icon">
              <img src={icon || ""}></img>
            </div>
            <div className="brri-card-value theme-text">
              {value || ""}
              <span>{unit}</span>
            </div>
          </div>
          <div className="theme-text brri-card-title">{title || ""}</div>
        </div>
      </div>
    </div>
  );
}

export default BrriValueCard;
