// import { Button } from "bootstrap";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import { FaEdit, FaImage } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import DeleteModal from "../common/deleteModal";
import CreateRoomCard from "./createRoomCard";

function RoomConfiguration() {
  const [show, setShow] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const theme = useContext(ThemeContext);

  const onClose = () => {
    setShow(false);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div style={{ textAlign: "right" }}>
        <button
          type="submit"
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
          }}
        >
          Create Room
        </button>
      </div>
      <Modal
        centered
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <div className={`theme-background theme-text ${theme}`}>
          <CreateRoomCard step={-1} />
        </div>
      </Modal>
      <div
        className="theme-background theme-text"
        style={{ paddingTop: "1rem", border: "0px" }}
      >
        <div
          className="table-responsive theme-background theme-text"
          style={{ border: "0px", paddingTop: "1rem" }}
        >
          <table
            style={{ textAlign: "center" }}
            className="table editable-table table-nowrap table-bordered table-edit theme-background theme-text "
          >
            <thead className="theme-background theme-text">
              <tr className="theme-background theme-text">
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Premises Name
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Building No.
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Room Name
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Level
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Room Width
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Room Length
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Room Capacity(sq. feet)
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Room Height(feet)
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Room Location
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Product Type
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Map of the Room
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Edit
                </th>
                <th style={{ color: theme == "dark" ? "black" : "white" }}>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr data-id="1">
                <td data-field="pname">pname</td>
                <td data-field="bno">1</td>
                <td data-field="rName">name</td>
                <td data-field="level">1</td>
                <td data-field="width">dummy</td>
                <td data-field="length">dummy</td>
                <td data-field="capacity">dummy</td>
                <td data-field="height">25</td>
                <td data-field="loc">dummyLocation</td>
                <td data-field="prod">dummytype</td>
                <td style={{ width: "100px" }}>
                  <a
                    className="btn btn-primary fs-14 text-white edit-icn"
                    title="view"
                    onClick={() => {
                      //TODO
                      //show map here
                    }}
                  >
                    <FaImage />
                  </a>
                </td>
                <td style={{ width: "100px" }}>
                  <a
                    className="btn btn-primary fs-14 text-white edit-icn"
                    title="Edit"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    <FaEdit />
                  </a>
                </td>
                <td style={{ width: "100px" }}>
                  <a
                    className="btn btn-primary fs-14 text-white edit-icn"
                    title="Edit"
                    onClick={() => {
                      setShowDelete(true);
                    }}
                  >
                    <RiDeleteBin2Fill />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <DeleteModal
          title={"You are about to delete this alert permanently."}
          onClose={() => {
            setShowDelete(false);
          }}
          handleSubmit={() => {
            //todo
          }}
          toggleModal={showDelete}
        />
      </div>
    </div>
  );
}

export default RoomConfiguration;
