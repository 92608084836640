import React, { useContext, useEffect } from "react";
import useTitle from "../../../hooks/useTitle";
import { ThemeContext } from "../../../context/ThemeContext";
import atm_pressure from "./asset/atm_pressure.png";
import wind_icon from "./asset/wind.png";
import solar_voltage from "./asset/solar_voltage.png";
import battery_voltage from "./asset/battery_voltage.png";
import battery_charging_current from "./asset/battery_charging_current.png";
import batter_charging from "./asset/battery_charging.png";

//graph icons
import co2 from "./asset/graphAssets/co2.png";
import rainfall from "./asset/graphAssets/rainfall.png";
import co from "./asset/graphAssets/co.png";
import ch4 from "./asset/graphAssets/ch4.png";
import pm2_5 from "./asset/graphAssets/pm2_5.png";
import pm_10 from "./asset/graphAssets/pm_10.png";
import temp from "./asset/graphAssets/temp.png";
import humidity from "./asset/graphAssets/humidity.png";

//soil icons
import ph from "./asset/soilAssets/ph.png";
import moisture from "./asset/soilAssets/moisture.png";
import conductivity from "./asset/soilAssets/conductivity.png";
import nitrogen from "./asset/soilAssets/nitrogen.png";
import phosphorus from "./asset/soilAssets/phosphorus.png";
import potassium from "./asset/soilAssets/potassium.png";
import water_level from "./asset/soilAssets/water_level.png";

import "./styles/brriStyle.css";
import BrriValueCard from "./BrriValueCard";
import BrriGraphCard from "./BrriGraphCard";
import BrriLineChart from "./BrriLineChart";
import BrriGauge from "./BrriGauge";
import Temp from "./Temp";
import { useGetBrriDashboardDataQuery } from "../../../features/brri/dashboardApi";
import { useLazyGetBrriDashboardDataQuery } from "../../../features/brri/dashboardApi";

import mqtt from "mqtt/dist/mqtt";
import timeAgo from "../../../helper/timeAgo";
import BrriSoilCard from "./BrriSoilCard";

import { generatePath, useNavigate } from "react-router-dom";

import { TbListDetails } from "react-icons/tb";

function BrriDashboard() {
  useTitle("Brri Dashboard");
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  let { data, isLoading } = useGetBrriDashboardDataQuery();
  const [getBrriDashboardData, results] = useLazyGetBrriDashboardDataQuery();

  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8082,
  };

  let client = mqtt.connect("mqtts://mqtt.aqualinkbd.com", options);
  useEffect(() => {
    client.subscribe("/dashboard/data");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      getBrriDashboardData();
    });
  }, [client]);

  const icons = {
    SOLARVOLTAGE: solar_voltage,
    BATTERYVOLTAGE: battery_voltage,
    BATTERYCHARGINGCURRENT: battery_charging_current,
    DEVICECURRENT: batter_charging,
    ATMPRESSURE: atm_pressure,
    WINDSPEED: wind_icon,
    WINDDIRECTION: wind_icon,
    "PM2.5": pm2_5,
  };
  const graphIcons = {
    TEMP: temp,
    HUM: humidity,
    RAINFALL: rainfall,
    CO2: co2,
    CO: co,
    CH4: ch4,
    PM10: pm_10,
    ATMPRESSURE: atm_pressure,
    "PM2.5": pm2_5,
  };
  const soilIcons = {
    PH: ph,
    MOISTURE: moisture,
    SOILTEMP: temp,
    CONDUCTIVITY: conductivity,
    NITROGEN: nitrogen,
    PHOSPHORUS: phosphorus,
    POTASSIUM: potassium,
    WATERLEVEL: water_level,
  };
  let brriCardData = [];
  let gaugeData = [];
  let soil_device_1_data = [];
  let soil_device_2_data = [];
  let soil_device_3_data = [];
  let soil_device_4_data = [];
  let soil_device_5_data = [];
  let soil_device_6_data = [];

  let time_ago = "";
  if (!isLoading) {
    if (results.data) {
      // console.log("results");
      // console.log(results.data);
      data = results?.data;
    }
    // console.log(data);
    brriCardData = data?.data;
    gaugeData = data?.gauge_data;
    soil_device_1_data = data?.soil_device_1;
    soil_device_2_data = data?.soil_device_2;
    soil_device_3_data = data?.soil_device_3;
    soil_device_4_data = data?.soil_device_4;
    soil_device_5_data = data?.soil_device_5;
    soil_device_6_data = data?.soil_device_6;
    brriCardData = brriCardData.map((item) => {
      const iconName = item.sensor_name.split(" ").join("");

      return {
        ...item,
        iconName: iconName,
      };
    });
    gaugeData = gaugeData.map((item) => {
      const iconName = item.sensor_name.split(" ").join("");

      return {
        ...item,
        iconName: iconName,
      };
    });
    soil_device_1_data = soil_device_1_data.map((item) => {
      const iconName = item.sensor_name.split(" ").join("");

      return {
        ...item,
        iconName: iconName,
      };
    });
    soil_device_2_data = soil_device_2_data.map((item) => {
      const iconName = item.sensor_name.split(" ").join("");

      return {
        ...item,
        iconName: iconName,
      };
    });
    soil_device_3_data = soil_device_3_data.map((item) => {
      const iconName = item.sensor_name.split(" ").join("");

      return {
        ...item,
        iconName: iconName,
      };
    });
    soil_device_4_data = soil_device_4_data.map((item) => {
      const iconName = item.sensor_name.split(" ").join("");

      return {
        ...item,
        iconName: iconName,
      };
    });
    soil_device_5_data = soil_device_5_data.map((item) => {
      const iconName = item.sensor_name.split(" ").join("");

      return {
        ...item,
        iconName: iconName,
      };
    });
    let latestTimeStamp = new Date(brriCardData[0]?.updated_at);
    // console.log(latestTimeStamp);

    if (latestTimeStamp) {
      time_ago = timeAgo(latestTimeStamp);
    }
  }

  const soilClickHandler = (slave_id) => {
    const path = generatePath("/soilDetails/:slaveId", { slaveId: slave_id });
    navigate(path);
  };

  return (
    <div>
      <div className={`container-fluid ${theme}`}>
        <div className="card theme-background">
          <div
            className="brri-timestamp theme-text"
            style={{ textAlign: "center", padding: "10px", fontSize: "18px" }}
          >
            Last Updated : {time_ago} (BRRI Gazipur)
          </div>
        </div>

        <div className="row ">
          {brriCardData.map((cardInfo) => {
            return (
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-2">
                <BrriValueCard
                  icon={icons[cardInfo?.iconName]}
                  value={cardInfo?.value}
                  unit={cardInfo?.unit}
                  title={cardInfo?.sensor_name}
                />
              </div>
            );
          })}
        </div>

        <div className="row">
          {/* <div className="col-md-4">
            <div className="card theme-background brri-graph-container">
              <div className="row">
                <div className="col-md-3 brri-graph-container">
                  <div className="brri-graph-title theme-text">Temperature</div>
                  <div className="brri-graph-icon">
                    <Temp value={25} />
                  </div>
                </div>
                <div className="col-md-9">
                  <BrriLineChart />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card theme-background brri-graph-container">
              <div className="row">
                <div className="col-md-3 brri-graph-container">
                  <div className="brri-graph-title theme-text">Humidity</div>
                  <div className="brri-graph-icon">
                    <BrriGauge value={80} unit={"%"} />
                  </div>
                  <div className="brri-graph-value theme-text">80 %</div>
                </div>
                <div className="col-md-9">
                  <BrriLineChart />
                </div>
              </div>
            </div>
          </div> */}
          {gaugeData.map((gaugeItem) => {
            const chartData = {
              label: gaugeItem?.chart_Label,
              value: gaugeItem?.chart_value,
            };
            return (
              <div className="col-md-6 col-xl-4 col-lg-4 col-sm-6">
                <BrriGraphCard
                  value={gaugeItem?.value}
                  title={gaugeItem?.sensor_name}
                  unit={gaugeItem?.unit}
                  icon={graphIcons[gaugeItem?.iconName]}
                  chartData={chartData}
                />
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-lg-6 col-sm-12">
            <div className="card theme-background brri-graph-container">
              <div className="">
                <div
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                  <h5 className="soil-card-title theme-text">AWD With Soil Data</h5>
                  <div
                      className="details-text theme-text"
                      onClick={() => soilClickHandler(1)}
                  >
                    <TbListDetails/>
                    details
                  </div>
                </div>

                <div className="row">
                  {soil_device_4_data.map((soilData) => {
                    return (
                        <div className="card theme-background col-md-3 ">
                          <BrriSoilCard
                              sensorName={soilData?.sensor_name}
                              value={soilData?.value}
                              unit={soilData?.unit}
                              icon={soilIcons[soilData?.iconName]}
                          />
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-6 col-lg-6 col-sm-12">
            <div className="card theme-background brri-graph-container">
              <div className="">
                <div
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                  <h5 className="soil-card-title theme-text">Soil Device 1</h5>
                  <div
                      className="details-text theme-text"
                      onClick={() => soilClickHandler(20)}
                  >
                    <TbListDetails/>
                    details
                  </div>
                </div>

                <div className="row">
                  {soil_device_1_data.map((soilData) => {
                    return (
                        <div className="card theme-background col-md-3 ">
                          <BrriSoilCard
                              sensorName={soilData?.sensor_name}
                              value={soilData?.value}
                              unit={soilData?.unit}
                              icon={soilIcons[soilData?.iconName]}
                          />
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-6 col-lg-6 col-sm-12">
            <div className="card theme-background brri-graph-container">
              <div className="">
                <div
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                  <h5 className="soil-card-title theme-text">Soil Device 2</h5>
                  <div
                      className="details-text theme-text"
                      onClick={() => soilClickHandler(21)}
                  >
                    <TbListDetails/>
                    details
                  </div>
                </div>

                <div className="row">
                  {soil_device_2_data.map((soilData) => {
                    return (
                        <div className="card theme-background col-md-3 ">
                          <BrriSoilCard
                              sensorName={soilData?.sensor_name}
                              value={soilData?.value}
                              unit={soilData?.unit}
                              icon={soilIcons[soilData?.iconName]}
                          />
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-6 col-lg-6 col-sm-12">
            <div className="card theme-background brri-graph-container">
              <div className="">
                <div
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                  <h5 className="soil-card-title theme-text">Soil Device 3</h5>
                  <div
                      className="details-text theme-text"
                      onClick={() => soilClickHandler(22)}
                  >
                    <TbListDetails/>
                    details
                  </div>
                </div>

                <div className="row">
                  {soil_device_3_data.map((soilData) => {
                    return (
                        <div className="card theme-background col-md-3 ">
                          <BrriSoilCard
                              sensorName={soilData?.sensor_name}
                              value={soilData?.value}
                              unit={soilData?.unit}
                              icon={soilIcons[soilData?.iconName]}
                          />
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-6 col-lg-6 col-sm-12">
            <div className="card theme-background brri-graph-container">
              <div className="">
                <div
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                  <h5 className="soil-card-title theme-text">AWD Data</h5>
                  <div
                      className="details-text theme-text"
                      onClick={() => soilClickHandler(23)}
                  >
                    <TbListDetails/>
                    details
                  </div>
                </div>

                <div className="row">
                  {soil_device_5_data.map((soilData) => {
                    return (
                        <div className="card theme-background col-md-3 ">
                          <BrriSoilCard
                              sensorName={soilData?.sensor_name}
                              value={soilData?.value}
                              unit={soilData?.unit}
                              icon={soilIcons[soilData?.iconName]}
                          />
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {/*AWD 2 Card Add :: Nabiaz*/}

          <div className="col-md-12 col-xl-6 col-lg-6 col-sm-12">
            <div className="card theme-background brri-graph-container">
              <div className="">
                <div
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                  <h5 className="soil-card-title theme-text">AWD Data 2</h5>
                  <div
                      className="details-text theme-text"
                      onClick={() => soilClickHandler(24)}
                  >
                    <TbListDetails/>
                    details
                  </div>
                </div>

                <div className="row">
                  {soil_device_6_data.map((soilData) => {
                    return (
                        <div className="card theme-background col-md-3 ">
                          <BrriSoilCard
                              sensorName={soilData?.sensor_name}
                              value={soilData?.value}
                              unit={soilData?.unit}
                              icon={soilIcons[soilData?.iconName]}
                          />
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default BrriDashboard;
