import React from "react";

function BrriSoilCard({ sensorName, value, unit, icon }) {
  return (
    <div>
      <div className="brri-soil-container">
        <div className="text-area">
          <div className="soil-sensor-title theme-text"> {sensorName}</div>
          <div className="soil-value theme-text">
            {value}
            <span>{unit}</span>
          </div>
        </div>
        <div className="soil-icon">
          <img src={icon}></img>
        </div>
      </div>
    </div>
  );
}

export default BrriSoilCard;
