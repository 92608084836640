import React from "react";
import BrriLineChart from "../BrriLineChart";

function SoilGraphCard({ lineChartData, title }) {
  return (
    <div>
      <div className="card theme-background" style={{ padding: "15px" }}>
        
        <div className="row">
          <div className="col-md-12">
            <BrriLineChart title={title} chartData={lineChartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoilGraphCard;
