import React from "react";
import { Line } from "react-chartjs-2";

const BrriLineChart = ({ title, chartData }) => {
  const data = {
    // labels: ["January", "February", "March", "April", "May", "June", "July"],
    labels: chartData?.label,
    datasets: [
      {
        label: title,
        // data: [65, 59, 80, 81, 56, 55, 40], // Sample data points
        data: chartData?.value, // Sample data points
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default BrriLineChart;
