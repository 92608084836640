import { apiSlice } from "../api/apiSlice";

export const labDashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrriLabDashboardData: builder.query({
      query: () => "/apiV2/lab-dashboard",
    }),
    // updateDeviceConfigList: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/devices-config",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["deviceConfig"],
    // }),
  }),
});

export const {
  useGetBrriLabDashboardDataQuery,
  useLazyGetBrriLabDashboardDataQuery,

  //   useUpdateDeviceConfigListMutation,
} = labDashboardApi;
