import { apiSlice } from "../api/apiSlice";

export const sensorSwitchApi = apiSlice.injectEndpoints({
  tagTypes: ["sensor-switch"],
  endpoints: (builder) => ({
    sensorSwitch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/sensor-switch",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["sensor-switch"],
    }),
    getSensorSwitchStatus: builder.query({
      query: () => "/apiV2/sensor-switch-status",
      providesTags: ["sensor-switch"],
    }),
  }),
});

export const {
  useSensorSwitchMutation,
  useGetSensorSwitchStatusQuery,
  // useMotorAutoManualSetMutation,
} = sensorSwitchApi;
