import React, { useContext } from "react";
import { useGetBrriLabDashboardDataQuery } from "../../../../features/brri/labDashboardApi";
import LabLineChartContainer from "./LabLineChartContainer";
import timeAgo from "../../../../helper/timeAgo";
import useTitle from "../../../../hooks/useTitle";
import { ThemeContext } from "../../../../context/ThemeContext";

function LabDetailsLineChartMain() {
  useTitle("Lab Dashboard Details");
  const theme = useContext(ThemeContext);

  let { data, isLoading } = useGetBrriLabDashboardDataQuery();
  let gaugeData = [];
  let time_ago = "";
  if (!isLoading) {
    gaugeData = data?.soil_device_5;
    let latestTimeStamp = new Date(gaugeData[0]?.updated_at);
    if (latestTimeStamp) {
      time_ago = timeAgo(latestTimeStamp);
    }
  }

  return (
    <div className={`container-fluid ${theme}`}>
      <div className="card theme-background">
        <div
          className="brri-timestamp theme-text"
          style={{ textAlign: "center", padding: "10px", fontSize: "18px" }}
        >
          Last Updated : {time_ago} (BRRI Gazipur)
        </div>
      </div>
      <div className="row">
        {gaugeData?.map((gaugeItem) => {
          return (
            <div className="col-md-6 col-xl-4 col-lg-4 col-sm-6">
              <div className="card theme-background brri-graph-container">
                <div className="row">
                  <div
                    // className="col-md-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="brri-graph-title theme-text">
                      {gaugeItem?.sensor_name || "title"} (Slave:
                      {gaugeItem?.slave_address})
                    </div>

                    <div
                      className="brri-graph-value theme-text"
                      style={{ fontSize: "20px" }}
                    >
                      {gaugeItem?.value || 0}
                      <span className="brri-unit">{gaugeItem?.unit || ""}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <LabLineChartContainer />
    </div>
  );
}

export default LabDetailsLineChartMain;
