import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SoilGraphCard from "./SoilGraphCard";
import { useSoilChartDataFetchMutation } from "../../../../features/brri/soilDetailsApi";
import useTitle from "../../../../hooks/useTitle";
import ReactLoading from "react-loading";
import timeAgo from "../../../../helper/timeAgo";

function SoilDetailsGraph() {
  useTitle("Soil Details");
  const { slaveId } = useParams();
  const [chartData, setChartData] = useState([]);

  const [soilChartDataFetch, { isLoading }] = useSoilChartDataFetchMutation();

  useEffect(() => {
    soilChartDataFetch({ slave_id: slaveId })
      .unwrap()
      .then((payload) => setChartData(payload?.chart_data))
      .catch((error) => console.log(error?.status));
  }, [soilChartDataFetch]);

  let element = "";
  if (isLoading) {
    element = (
      <div className="container loading-center">
        <ReactLoading type="spin" color="#6d6dae" height={100} width={50} />
      </div>
    );
  }
  let time_ago = "";
  if (!isLoading) {
    let latestTimeStamp = new Date(chartData[0]?.updated_at);
    if (latestTimeStamp) {
      time_ago = timeAgo(latestTimeStamp);
    }

    element = (
      <div>
        <div className="card theme-background">
          <div
            className="brri-timestamp theme-text"
            style={{ textAlign: "center", padding: "10px", fontSize: "18px" }}
          >
            Last Updated : {time_ago} (BRRI Gazipur)
          </div>
        </div>
        <div className="row">
          {chartData?.map((data) => {
            const lineChartData = {
              label: data?.chart_Label,
              value: data?.chart_value,
            };
            return (
              <div className="col-md-4">
                <SoilGraphCard
                  lineChartData={lineChartData}
                  title={data?.sensor_name}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return <div>{element}</div>;
}

export default SoilDetailsGraph;
